exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-blog-details-js": () => import("./../../../src/pages/blog/blog-details.js" /* webpackChunkName: "component---src-pages-blog-blog-details-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demos-b-2-b-saas-console-for-advanced-ransomware-protection-js": () => import("./../../../src/pages/demos/b2b-saas-console-for-advanced-ransomware-protection.js" /* webpackChunkName: "component---src-pages-demos-b-2-b-saas-console-for-advanced-ransomware-protection-js" */),
  "component---src-pages-demos-b-2-b-saas-platform-for-ai-alignment-as-a-service-js": () => import("./../../../src/pages/demos/b2b-saas-platform-for-ai-alignment-as-a-service.js" /* webpackChunkName: "component---src-pages-demos-b-2-b-saas-platform-for-ai-alignment-as-a-service-js" */),
  "component---src-pages-demos-debt-collection-management-system-js": () => import("./../../../src/pages/demos/debt-collection-management-system.js" /* webpackChunkName: "component---src-pages-demos-debt-collection-management-system-js" */),
  "component---src-pages-demos-index-js": () => import("./../../../src/pages/demos/index.js" /* webpackChunkName: "component---src-pages-demos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platforms-assets-management-js": () => import("./../../../src/pages/platforms/assets-management.js" /* webpackChunkName: "component---src-pages-platforms-assets-management-js" */),
  "component---src-pages-platforms-carbon-market-js": () => import("./../../../src/pages/platforms/carbon-market.js" /* webpackChunkName: "component---src-pages-platforms-carbon-market-js" */),
  "component---src-pages-platforms-green-it-js": () => import("./../../../src/pages/platforms/green-it.js" /* webpackChunkName: "component---src-pages-platforms-green-it-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-ai-ml-js": () => import("./../../../src/pages/services/ai-ml.js" /* webpackChunkName: "component---src-pages-services-ai-ml-js" */),
  "component---src-pages-services-api-enablement-js": () => import("./../../../src/pages/services/api-enablement.js" /* webpackChunkName: "component---src-pages-services-api-enablement-js" */),
  "component---src-pages-services-block-chain-js": () => import("./../../../src/pages/services/block-chain.js" /* webpackChunkName: "component---src-pages-services-block-chain-js" */),
  "component---src-pages-services-cloud-adoption-js": () => import("./../../../src/pages/services/cloud-adoption.js" /* webpackChunkName: "component---src-pages-services-cloud-adoption-js" */),
  "component---src-pages-services-consulting-advisory-js": () => import("./../../../src/pages/services/consulting-advisory.js" /* webpackChunkName: "component---src-pages-services-consulting-advisory-js" */),
  "component---src-pages-services-devops-js": () => import("./../../../src/pages/services/devops.js" /* webpackChunkName: "component---src-pages-services-devops-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-quality-assurance-js": () => import("./../../../src/pages/services/quality-assurance.js" /* webpackChunkName: "component---src-pages-services-quality-assurance-js" */),
  "component---src-pages-services-software-development-js": () => import("./../../../src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

